import { isEqual } from 'lodash'

import { unlocode } from '../../../Domain/NauticalLocations/IPort'
import { TerminalUuid } from '../../../Domain/Terminals/TerminalUuid'
import { areBothNoneOr, arraysAreEqual, stringsAreEqual, shipIdsAreEqual } from '../../../utils/equals'
import { None } from '../../../utils/strictNull'

import type { BerthUuid } from '../../../Domain/NauticalLocations/IBerth'
import type { Unlocode } from '../../../Domain/NauticalLocations/IPort'
import type { EventPortcallId } from '../../../Domain/Portcall/IPortcall'
import type { ScheduleId } from '../../../Domain/Schedule/ScheduleId'
import type { IShipId } from '../../../Domain/VesselDetails/IVesselDetails'
import type { Carrier } from './Carrier'
import type { AgentName } from './IAgent'

// Definitions for Pronto React Filters
// These are NOT compatible with Pronto Angular!
export interface IDateRange {
  start: number
  end: number
  unit: 'day' | 'hour' | 'minute'
}

function dateRangesAreEqual(left: IDateRange, right: IDateRange) {
  return left.start === right.start && left.end === right.end && left.unit === right.unit
}

export interface IFilters {
  ship: IShipId | None
  ports: Unlocode[]
  terminalUuids: Array<TerminalUuid | None>
  berthUuids: BerthUuid[]
  agents: AgentName[]
  carriers: Carrier[]
  schedules: ScheduleId[]
  dateRange: IDateRange
  portcallIds: EventPortcallId[]
}

const shipIdsAreEqualOrNone = areBothNoneOr(shipIdsAreEqual)

export const scheduleIdsAreEqual = arraysAreEqual(stringsAreEqual)

export const filtersAreEqual = (left: IFilters, right: IFilters): boolean =>
  // Kill me, why do we need to sort. Doesn't lodash take care of that?
  [
    () => isEqual(left.agents.sort(), right.agents.sort()),
    // We deliberately ignore the selected terminals, as the resulting selected
    // berths is what really counts.
    () => isEqual(left.berthUuids.slice().sort(), right.berthUuids.slice().sort()),
    () => isEqual(left.portcallIds.sort(), right.portcallIds.sort()),
    () => dateRangesAreEqual(left.dateRange, right.dateRange),
    () => unlocode.many.equals(left.ports, right.ports),
    () => shipIdsAreEqualOrNone(left.ship, right.ship),
    () => scheduleIdsAreEqual(left.schedules, right.schedules),
    () => isEqual(left.carriers.sort(), right.carriers.sort()),
    // let's quit on the first failed check
  ].every(fn => fn())
